import { CustomVideo, FlexBox, Loader, Modal, theme } from '@rario/shared-components'
import { getCMSPageContents } from 'api/CMS.api'
// import RarioAmbassadorsSection from 'components/HomeLanding/RarioAmbassadorsSection'
// import HowItWorksBanner from 'components/HomeLanding/HowItWorksBanner'
import { RARIO_WEBVIEW_APK_URL } from 'config'
import { FirstFoldData } from 'interfaces/homePage'
import React, { useContext, useEffect, useMemo, useState } from 'react'
// import WinnersSection from 'components/HomeLanding/WinnersSection'
// import Investors from 'components/Investors'
import { getCDNUrl } from 'utils/utils'
import { IApiException } from 'api/Api.exception'
import { emitCustomEvent } from 'components/CustomEvents'
import HomeLandingHeader from 'components/HomeLanding/HomeLandingHeader'
import HomeLandingFooter from 'components/HomeLanding/HomeLandingFooter'
import HomeLandingFirstFold from 'components/HomeLanding/HomeLandingFirstFold'
// import HomeLandingStickyBox from './HomeLandingStickyBox'
import { fbPixelScript } from 'constant'
// import DownloadBanner from './DownloadBanner'
import usePageControl from 'hooks/usePageControl'
// import FaqSection from 'components/FaqSection'
import { AppConfigContext } from 'contexts/AppConfigContext'
import LandingFirstFold from './LandingFirstFold'
import SEO from 'components/Seo'
// import { ThemeEnum } from 'interfaces'

const HomeLanding: React.FunctionComponent<{ appDownloadLink?: string; firstFoldId?: string }> = ({
  appDownloadLink = RARIO_WEBVIEW_APK_URL,
  firstFoldId,
}) => {
  // const [homePageData, setHomePageData] = useState<HomePageData>()
  const [firstFoldData, setFirstFoldData] = useState<FirstFoldData>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  // const [mobileView, setMobileView] = useState<boolean>(false)
  const [playVideo, setPlayVideo] = useState<boolean>(false)
  // const { isMobile } = useUserAgent()

  const {
    isHomeLandingPage,
    isDream11Landing,
    isPpcLanding,
    isDreamSquadLanding,
    isDreamTeamLanding,
    isCricketFandomLanding,
  } = usePageControl()

  const data = useContext(AppConfigContext)
  const homePageID = data?.cmsPages.homePageID

  // const getHomePageData = async () => {
  //   try {
  //     const { data } = await getCMSPageContents(homePageID as string)
  //     setHomePageData(data)
  //     setIsLoading(false)
  //   } catch (e) {
  //     const error = e as IApiException
  //     const errorResponse = error.response ? error.response : { message: error.message }
  //     emitCustomEvent('errorCodes', errorResponse)
  //     setIsLoading(false)
  //     throw e
  //   }
  // }
  const getLandingPagesData = async () => {
    Promise.all([
      getCMSPageContents(homePageID as string),
      getCMSPageContents(firstFoldId as string),
    ])
      .then(([{ data: firstFoldData }]) => {
        // setHomePageData(homePageData)
        setFirstFoldData(firstFoldData)
        setIsLoading(false)
      })
      .catch((e) => {
        const error = e as IApiException
        const errorResponse = error.response ? error.response : { message: error.message }
        emitCustomEvent('errorCodes', errorResponse)
        setIsLoading(false)
        throw e
      })
  }

  useEffect(() => {
    if (isHomeLandingPage) {
      setIsLoading(false)
      // if (homePageID) getHomePageData()
    } else {
      if (homePageID && firstFoldId) getLandingPagesData()
    }
  }, [homePageID, firstFoldId])

  // useEffect(() => {
  //   setMobileView(isMobile)
  // }, [isMobile])

  const FirstFold = useMemo(() => {
    if (!isHomeLandingPage) {
      return <LandingFirstFold data={firstFoldData} />
    } else {
      return <HomeLandingFirstFold />
    }
  }, [appDownloadLink, firstFoldData])

  if (isLoading) {
    return <Loader />
  }

  const isDream11Any =
    isDream11Landing || isDreamSquadLanding || isDreamTeamLanding || isCricketFandomLanding

  const bannerPoster = getCDNUrl('/App/images/rario-landing/how-it-works-poster-v2.webp')
  const bannerVideo = getCDNUrl('/App/videos/rario-landing/how-it-works-video.mp4')

  return (
    <>
      {(isDream11Any || isPpcLanding) && (
        <SEO
          title={'RARIO | Buy Licensed Cricket Digital Player Cards And Play D3'}
          description={`World's first officially licensed digital collectibles platform where you can buy cricket digital player cards, participate in FREE contests and win awesome rewards.`}
          keywords="Cricket Digital Player Card, Cricket Digital Player Cards, Cricket Digital Player Card Marketplace, Digital Collectibles Platform, Australian Cricket Digital Player Cards, Cricket player Digital Player Card, Team Digital Player Cards, Cricket Digital Player Card Collections"
        />
      )}
      <script id="facebook-pixel" defer>
        {fbPixelScript()}
      </script>
      <FlexBox
        flexDirection={'column'}
        maxWidth={theme.breakpoints[0]}
        overflowY="scroll"
        mb={'30px'}
      >
        <FlexBox top={0} position={'absolute'} flexDirection="column" width="100%">
          <HomeLandingHeader />
        </FlexBox>

        {FirstFold}
        {/* 
        {isDream11Any ? (
          <>
            {homePageData?.testimonials && (
              <WinnersSection
                testimonials={homePageData?.testimonials}
                isDreamSquad={isDreamSquadLanding}
              />
            )}
            <HowItWorksBanner
              bannerVideo={getCDNUrl('/App/videos/rario-landing/how-it-works-video.mp4')}
              bannerPoster={getCDNUrl('/App/images/rario-landing/how-it-works-poster-v2.webp')}
              setPlayVideo={setPlayVideo}
              isDream11={isDream11Any}
            />
          </>
        ) : (
          <>
            <HowItWorksBanner
              bannerVideo={getCDNUrl('/App/videos/rario-landing/how-it-works-video.mp4')}
              bannerPoster={getCDNUrl(
                '/App/images/rario-landing/how-it-works-poster-v2.webp',
                true
              )}
              setPlayVideo={setPlayVideo}
              isDream11={isDream11Landing}
            />
            {homePageData?.testimonials && PLATFORM_ORIGIN === ThemeEnum.RARIO && (
              <WinnersSection testimonials={homePageData?.testimonials} />
            )}
          </>
        )} */}

        {/* <RarioAmbassadorsSection isDream11={isDream11Any} />

        {homePageData?.investersSection && PLATFORM_ORIGIN === ThemeEnum.RARIO && (
          <Investors investors={homePageData?.investersSection} />
        )} */}

        {/* {isDream11Any && homePageData?.faqSection && (
          <FaqSection faqSection={homePageData?.faqSection} isDream11Any />
        )} */}

        <HomeLandingFooter
          showSocialLinks={true}
          appDownloadLink={appDownloadLink}
          isDream11={isDream11Any}
        />

        {/* {PLATFORM_ORIGIN === ThemeEnum.RARIO &&
          (mobileView ? (
            <HomeLandingStickyBox
              appDownloadLink={appDownloadLink}
              isDream11={isDream11Any || isPpcLanding}
            />
          ) : (
            <DownloadBanner isDream11={isDream11Any || isPpcLanding} />
          ))} */}
      </FlexBox>

      {playVideo && (
        <Modal
          visible={true}
          showCloseIcon={true}
          background="#000"
          onClick={() => setPlayVideo(false)}
          isOutsideClick={true}
          showTill={5000}
          variant="fullScreenVideo"
        >
          <FlexBox maxHeight={'85vh'} maxWidth={'50vh'} mx="auto">
            <CustomVideo
              height={'100%'}
              width={'100%'}
              loop={false}
              play={true}
              isMute={false}
              media={{
                poster: bannerPoster,
                src: bannerVideo || '',
              }}
              mediaType="video/mp4"
              showManualControls
              controlsPositionBottom={'20px'}
              controlsPositionLeft={'10px'}
              onEnd={() => setPlayVideo(false)}
            />
          </FlexBox>
        </Modal>
      )}
    </>
  )
}

export default HomeLanding
